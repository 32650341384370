import React from 'react'
import logo from './logo.svg'
import { Counter } from './Counter'
import { Accounts } from './Accounts'
import { Header } from './Header'
import { Gallery } from './Gallery'
// import './App.css'

function App() {
  console.log(process.env)
  return (
    <div className="App">
      <Header />
      <Gallery />
    </div>
  )
}

export default App
