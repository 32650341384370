import { currentStage } from 'config'
import { parseDomain, ParseResultType } from 'parse-domain'
import { logger } from 'utilities'

export const getApiDomain = () => {
  return (
    process.env.REACT_APP_SERVICE_API ||
    (() => {
      const { host, protocol } = window.location

      const parseResult = parseDomain(host)

      if (parseResult.type === ParseResultType.Listed) {
        const { domain, topLevelDomains } = parseResult

        const consolidatedTopLevelDomain = topLevelDomains.join('.')

        const result = `${protocol}//${currentStage}.service.${domain}.${consolidatedTopLevelDomain}`

        logger.info('API domain:', result)
      } else {
        console.warn(parseResult)
        throw new Error('Domain cannot be parsed from current location.')
      }
    })()
  )
}
