"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.beta = void 0;
var constants_1 = require("./constants");
exports.beta = {
    applicationEnvironment: {
        account: '741740907498',
        region: 'us-east-1',
    },
    persistence: {
        itemsTable: "beta-".concat(constants_1.ITEMS_TABLE),
    },
};
